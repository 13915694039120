import React, {useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import eventColors from './eventColors';
import DateTimePicker from 'react-datetime-picker';

import './Dashboard.css';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const Dashboard = () => {
  const [events, setEvents] = useState([]); 
  const [showTeamModal, setShowTeamModal] = useState(false); 
  const [teamInfo, setTeamInfo] = useState({
    team: '',
    state: '',
    email: '',
    league: '',
    division: ''
});

  const [pasteMode, setPasteMode] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); 
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndDate] = useState(new Date()); 
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [availabilityModal, setAvailabilityModal] = useState({
    visible: false,
    action: null,
    event: null,
  });
  const [notification, setNotification] = useState(null); 
  const [copiedEvent, setCopiedEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());  
  const [currentView, setCurrentView] = useState("month");  
  

  const [allDay, setAllDay] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();
  const [newEvent, setNewEvent] = useState({
    title: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    startAmPm: 'PM',
    endAmPm: 'PM',
    type: 'Home',
    allDay: false,
  });
  const [recurringEvent, setRecurringEvent] = useState({
    frequency: '', 
    endDate: null,
  });
  

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      checkTeamAffiliation();
      fetchEvents();
    }
  }, [user, navigate]);

    const checkTeamAffiliation = async () => {
      try {
        const response = await fetch(`https://rinksync-backend.onrender.com/user/${user.sub}/team-info`, { //localhost:8080
          credentials: 'include',
        });
        const data = await response.json();
        if (data?.hasCompletedTeamInfo) {
          setTeamInfo({
            team: data.team || '',
            state: data.state || '',
            email: data.email || '',
            league: data.league || '',
            division: data.division || ''
          });
        } else {
          setShowTeamModal(true); 
        }
      } catch (error) {
        console.error('Error checking team affiliation:', error);
      }
    };
  
    // Save the team affiliation info
    const handleSaveTeamInfo = async () => {
      if (!teamInfo.team || !teamInfo.state || !teamInfo.email || !teamInfo.league || !teamInfo.division) {
        alert('Please fill out all fields.');
        return;
      }
    
      try {
        const response = await fetch(`https://rinksync-backend.onrender.com/user/${user.sub}/team-info`, { //localhost:8080
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(teamInfo),
          credentials: 'include',
        });
    
        if (response.ok) {
          setShowTeamModal(false); // Close the modal once info is saved
          showNotification('Team information saved successfully!');
        } else {
          throw new Error('Failed to save team info.');
        }
      } catch (error) {
        console.error('Error saving team information:', error);
        showNotification('Error saving team information.');
      }
    };
      
  
  const fetchEvents = async () => {
    try {
      
      const response = await fetch('https://rinksync-backend.onrender.com/events', { //localhost:8080

        credentials: 'include',
      });
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error('Error fetching events:', error);
      showNotification('Failed to load events.');
    }
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 3000); // Clear after 3 seconds
  };

  const handleAddAvailability = () => {
    const defaultTitle = teamInfo.team || '';

    console.log("Current Team Info:", teamInfo);

    console.log("Setting title to default:", defaultTitle);

    setNewEvent((prevEvent) => ({
      ...prevEvent,
      title: defaultTitle,
      startDate: '',
      endDate: '',
      startHour: '12',
      startMinute: '00',
      startAmPm: 'AM',
      endHour: '12',
      endMinute: '00',
      endAmPm: 'PM',
      type: 'Home',
      allDay: false,
  }));

    setRecurringEvent({
        frequency: '',
        endDate: null,
    });

    setSelectedEvent(null);
    setAvailabilityModal({ visible: true, action: 'add', event: null });
    setAllDay(false); 
};


  const handleEditAvailability = () => {
    if (!selectedEvent) {
        alert('Please select an event to edit.');
        return;
    }

    const startMoment = moment(selectedEvent.start);
    const endMoment = moment(selectedEvent.end);

    const isAllDay = selectedEvent.allDay || false;

    setNewEvent({
        title: selectedEvent.title || '',
        startDate: startMoment.format('YYYY-MM-DD'),
        endDate: endMoment.format('YYYY-MM-DD'),
        startHour: startMoment.format('h'),
        startMinute: startMoment.format('mm'),
        startAmPm: startMoment.format('A'),
        endHour: endMoment.format('h'),
        endMinute: endMoment.format('mm'),
        endAmPm: endMoment.format('A'),
        type: selectedEvent.type || 'Home',
        allDay: isAllDay,
    });
    setSelectedEvent(null);
    setAllDay(isAllDay);
    setAvailabilityModal({ visible: true, action: 'edit', event: selectedEvent });
};


  const handleDeleteAvailability = async () => {
    if (!selectedEvent) {
      alert('Please select an event to delete.');
      return;
    }
  
    const confirmDelete = window.confirm(
      `Are you sure you want to delete availability for "${selectedEvent.title}"?`
    );
    if (confirmDelete) {
      try {
        
        await fetch(`https://rinksync-backend.onrender.com/events/${selectedEvent.id}`, { //localhost:8080

          method: 'DELETE',
          credentials: 'include',
        });
  
        setEvents(events.filter((e) => e.id !== selectedEvent.id));
        showNotification(`Availability "${selectedEvent.title}" was deleted.`);
        setSelectedEvent(null); 
      } catch (error) {
        console.error('Error deleting event:', error);
        showNotification('Failed to delete event.');
      }
    }
  };

  const handleSaveAvailability = async (newEvent) => {
    try {
      console.log("DEBUG: Received new event input:", newEvent);
  
      if (!allDay && new Date(newEvent.start) > new Date(newEvent.end)) {
        console.error("ERROR: End date must be after the start date.");
        alert("End date must be after the start date.");
        return;
      }

      const selectedStartDate = moment(newEvent.start).startOf('day').format('YYYY-MM-DD');
        const existingEvent = events.find((event) =>
            moment(event.start).startOf('day').format('YYYY-MM-DD') === selectedStartDate
        );

        if (existingEvent) {
            alert(`An event already exists on ${newEvent.startDate}. Please select a different day.`);
            return;
        }
  
      // Debugging initial values before processing
      console.log("DEBUG: Initial allDay:", allDay);
      console.log("DEBUG: Initial event start:", newEvent.start);
      console.log("DEBUG: Initial event end:", newEvent.end);
  
      // Adjust `newEvent` for all-day or non-all-day
      if (allDay) {
        console.log("DEBUG: Processing all-day event...");

        if (!newEvent.startDate) {
          console.error("ERROR: Missing start date for all-day event.");
          alert("Start date is required for all-day events.");
          return;
        }
      
        // Fix: Keep date in local time instead of UTC to prevent shifting
        const formattedStartDate = moment(newEvent.startDate, 'YYYY-MM-DD').startOf('day'); 
        const formattedEndDate = newEvent.endDate
          ? moment(newEvent.endDate, 'YYYY-MM-DD').startOf('day')
          : formattedStartDate; // Default endDate to startDate if not provided
      
        newEvent = {
          ...newEvent,
          start: formattedStartDate.format(), 
          end: formattedEndDate.format(), // format to have local time
          allDay: true,
        };
      
        console.log("DEBUG: All-day event formatted correctly:", newEvent);
      } else {
        console.log("DEBUG: Processing time-based event...");


        console.log("DEBUG: Raw form values:");
        console.log("Start Date:", newEvent.startDate);
        console.log("Start Hour:", newEvent.startHour);
        console.log("Start Minute:", newEvent.startMinute);
        console.log("Start AM/PM:", newEvent.startAmPm);
        console.log("End Date:", newEvent.endDate);
        console.log("End Hour:", newEvent.endHour);
        console.log("End Minute:", newEvent.endMinute);
        console.log("End AM/PM:", newEvent.endAmPm);
        
        if (!newEvent.startDate || !newEvent.startHour || !newEvent.startMinute || !newEvent.startAmPm ||
            !newEvent.endDate || !newEvent.endHour || !newEvent.endMinute || !newEvent.endAmPm) {
            console.error("ERROR: Start and end date/time are required.");
            alert("Start and end date/time are required.");
            return;
        }

        const startTimeStr = `${newEvent.startHour}:${newEvent.startMinute} ${newEvent.startAmPm}`;
        const endTimeStr = `${newEvent.endHour}:${newEvent.endMinute} ${newEvent.endAmPm}`;

        console.log("DEBUG: Constructed startTimeStr:", startTimeStr);
        console.log("DEBUG: Constructed endTimeStr:", endTimeStr);

        const startMoment = moment(
            `${newEvent.startDate} ${startTimeStr}`,
            "YYYY-MM-DD hh:mm A"
        );

        const endMoment = moment(
            `${newEvent.endDate} ${endTimeStr}`,
            "YYYY-MM-DD hh:mm A"
        );

        console.log("DEBUG: Parsed startMoment:", startMoment ? startMoment.toISOString() : "Invalid");
        console.log("DEBUG: Parsed endMoment:", endMoment ? endMoment.toISOString() : "Invalid");

        const now  = moment();
        if (startMoment.isBefore(now)) {
          alert("Cannot create an event in the past.");
          return;
        }

        if (!startMoment.isValid() || !endMoment.isValid() || startMoment.isAfter(endMoment)) {
            console.error("ERROR: Invalid start or end time.");
            alert("Invalid start or end time.");
            return;
        }

        newEvent = {
            ...newEvent,
            start: startMoment.format(),
            end: endMoment.format(),
            allDay: false,
        };

        console.log("DEBUG: Time-based event formatted:", newEvent);
      }
  
      console.log("DEBUG: Creating recurring events...");
      const createRecurringEvents = () => {
        const events = [];
        if (recurringEvent.frequency) {
          let currentDate = moment(newEvent.start);
          const recurrenceEnd = moment(recurringEvent.endDate);
          const interval =
            recurringEvent.frequency === "daily"
              ? "days"
              : recurringEvent.frequency === "weekly"
              ? "weeks"
              : recurringEvent.frequency === "monthly"
              ? "months"
              : "years";
  
          while (currentDate.isSameOrBefore(recurrenceEnd)) {
            events.push({
              ...newEvent,
              start: allDay
                ? currentDate.startOf('day').toISOString()
                : currentDate.toISOString(),
              end: allDay
                ? currentDate.startOf('day').toISOString()
                : moment(currentDate)
                    .add(moment(newEvent.end).diff(moment(newEvent.start)))
                    .toISOString(),
            });
            currentDate.add(1, interval);
          }
        } else {
          events.push(newEvent);
        }
        return events;
      };
  
      const eventsToSave = createRecurringEvents();
      console.log("DEBUG: Events to be saved:", eventsToSave);

      const isEdit = availabilityModal.action === 'edit' && availabilityModal.event?.id;

      if (isEdit) {
          console.log("DEBUG: Updating existing event...");
          const updatedEvent = { ...availabilityModal.event, ...newEvent };

          const response = await fetch(`https://rinksync-backend.onrender.com/events/${updatedEvent.id}`, { //localhost:8080

              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              credentials: 'include',
              body: JSON.stringify(updatedEvent),
          });

          if (response.ok) {
              setEvents((prevEvents) =>
                  prevEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
              );
              await fetchEvents();
              showNotification(`Availability "${updatedEvent.title}" was updated.`);
          } else {
              const errorText = await response.text();
              console.error("ERROR: Failed to update event:", errorText);
              throw new Error(`Failed to update event: ${errorText}`);
          }

      } else {
  
      // Save events to the backend
      const savedEvents = [];
      for (const event of eventsToSave) {
        console.log("DEBUG: Sending event to backend:", event);
  
        const response = await fetch("https://rinksync-backend.onrender.com/events", { //localhost:8080

          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(event),
        });
  
        if (response.ok) {
          const savedEvent = await response.json();
          savedEvents.push(savedEvent);
          console.log("DEBUG: Successfully saved event:", savedEvent);
        } else {
          const errorText = await response.text();
          console.error("ERROR: Failed to save event:", errorText);
          throw new Error(`Failed to save event: ${errorText}`);
        }
      }
  
      // Debugging before updating state
      console.log("DEBUG: Updating state with saved events...");
      setEvents((prevEvents) => [...prevEvents, ...savedEvents]);
      await fetchEvents();
        showNotification(
          `Availability "${newEvent.title}" was added${recurringEvent.frequency ? " with recurrence" : ""}.`
        );
      }
    } catch (error) {
      console.error("ERROR: Exception while saving event:", error.message || error);
      showNotification("Failed to save event(s)."); //maybe say something about recurring events added up until x date where there was already an event
    } finally {
      console.log("DEBUG: Closing modal and clearing selection.");
      setAvailabilityModal({ visible: false, action: null, event: null });
      setSelectedEvent(null);
    }
  };
  

  // Handle resizing of events
  const handleEventResize = async ({ event, start, end }) => {
    const updatedEvent = { ...event, start, end };
    const now = new Date();
  
    if (new Date(start) < now || new Date(end) < now) {
      showNotification(`Cannot resize "${event.title}" to a time in the past.`);
      return; 
    }
    
    //don't allow the user to resize the event into the future, keep it in the same day
    if (new Date(end) > new Date(start).setHours(23, 59, 59, 999)) {
      showNotification(`Cannot resize "${event.title}" into a different date.`);
      return;
    }

    setEvents(events.map((e) => (e.id === event.id ? updatedEvent : e)));
  
    try {
      
      const response = await fetch(`https://rinksync-backend.onrender.com/events/${event.id}`, { //localhost:8080

        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEvent),
        credentials: 'include',
      });
  
      if (response.ok) {
        showNotification(`Availability "${event.title}" resized successfully.`);
      } else {
        console.error('Error updating event:', await response.text());
      }
    } catch (error) {
      console.error('Error resizing event:', error);
    }
  };
  
  
  useEffect(() => {
    const handleKeyDown = async (event) => {
      // Check for Ctrl+C
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        if (!selectedEvent) {
          showNotification('Please select an event to copy.');
          return;
        }
        setCopiedEvent(selectedEvent);
        showNotification(`Availability "${selectedEvent.title}" copied.`);
      }
  
      // Check for Ctrl+V
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        if (!copiedEvent) {
          showNotification('No event copied to paste.');
          return;
        }
  
        const now = moment();
        const newStart = moment.max(now, moment(copiedEvent.start)); // Ensure pasted event is in the future
        const duration = moment(copiedEvent.end).diff(moment(copiedEvent.start), 'minutes'); // Calculate duration
        const newEnd = newStart.clone().add(duration, 'minutes');
        const newEvent = {
          title: copiedEvent.title,
          start: newStart.toISOString(),
          end: newEnd.toISOString(),
          allDay: copiedEvent.allDay,
          type: copiedEvent.type,
        };
  
        try {
          // Call backend API to save the copied event as a new event
          const response = await fetch('https://rinksync-backend.onrender.com/events', { //localhost:8080

            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newEvent),
            credentials: 'include',
          });
  
          if (response.ok) {
            const createdEvent = await response.json();
            setEvents([...events, createdEvent]); // Add the new event to the local state
            await fetchEvents();
            showNotification(`Availability "${createdEvent.title}" pasted.`);
          } else {
            const error = await response.text();
            console.error('Error pasting event:', error);
            showNotification('Error pasting event.');
          }
        } catch (err) {
          console.error('Error:', err);
          showNotification('Error pasting event.');
        }
      }
          // Check for Backspace (Delete)
          if (event.key === "Backspace" && selectedEvent) {
            event.preventDefault(); // Prevent unintended browser navigation
            handleDeleteAvailability();
          }
    };
  
    // Add event listener
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedEvent, copiedEvent, events]); // Dependencies
  
  

  const handleEventDrop = async ({ event, start, end }) => {
    const targetDate = moment(start).format('YYYY-MM-DD');
    
    // Check if there is already an event on the target date
    const isDateOccupied = events.some((e) => 
        e.id !== event.id && 
        moment(e.start).format('YYYY-MM-DD') === targetDate
    );

    if (isDateOccupied) {
        showNotification(`Cannot move "${event.title}" to ${targetDate} as there is already an event.`);
        return;
    }
    
    const updatedEvent = { ...event, start, end };
    const now = new Date();
  
    if (new Date(start) < now) {
      showNotification(`Cannot move "${event.title}" to a time in the past.`);
      return;
    }



    setEvents(events.map((e) => (e.id === event.id ? updatedEvent : e)));
  
    try {
      
      const response = await fetch(`https://rinksync-backend.onrender.com/events/${event.id}`, { //localhost:8080

        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEvent),
        credentials: 'include',
      });
  
      if (response.ok) {
        showNotification(`Availability "${event.title}" moved successfully.`);
      } else {
        console.error('Error updating event:', await response.text());
      }
    } catch (error) {
      console.error('Error moving event:', error);
    }
  };
  

  const handleSelectEvent = (event) => {
    if (selectedEvent && selectedEvent.id === event.id) {
      // If the clicked event is already selected, unselect it
      setSelectedEvent(null);
      showNotification(`Availability "${event.title}" was unselected.`);
    } else {
      // Otherwise, select the event
      setSelectedEvent(event);
    }
  };

  const handleSelectSlot = async ({ start, end }) => {
    const now = moment(); // Current date and time
    const today = moment().startOf('day');

    if (moment(start).isBefore(today)) {
      showNotification('Cannot create an event in the past.');
      return;
    }

    if (pasteMode && copiedEvent) {
      console.log("Pasting event with original times:", {
        originalStart: copiedEvent.start,
        originalEnd: copiedEvent.end,
    });

      // Handle pasting the copied event
    const duration = moment(copiedEvent.end).diff(moment(copiedEvent.start), 'minutes');
    const newStart = moment(start).set({
        hour: moment(copiedEvent.start).hour(),
        minute: moment(copiedEvent.start).minute(),
    });

    const newEnd = newStart.clone().add(duration, 'minutes');

      
      const newEvent = {
          title: copiedEvent.title,
          start: newStart.toISOString(),
          end: newEnd.toISOString(),
          allDay: copiedEvent.allDay,
          type: copiedEvent.type,
      };

      console.log("New event with preserved times:", newEvent);


      try {
          const response = await fetch('https://rinksync-backend.onrender.com/events', { //localhost:8080
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(newEvent),
              credentials: 'include',
          });

          if (response.ok) {
              const createdEvent = await response.json();
              setEvents([...events, createdEvent]); // Add the new event to the local state
              await fetchEvents();
              showNotification(`Availability "${createdEvent.title}" pasted.`);
          } else {
              const error = await response.text();
              console.error('Error pasting event:', error);
              showNotification('Error pasting event.');
          }
      } catch (err) {
          console.error('Error:', err);
          showNotification('Error pasting event.');
      } finally {
          setPasteMode(false);
          setCopiedEvent(null);
      }
      return;
    }

    const title = prompt('Enter a title for your new event:');
  
    if (title) {
      const newEvent = {
        title,
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        allDay: false,
        type: 'Home', // Default type
      };
  
      try {
        
        const response = await fetch('https://rinksync-backend.onrender.com/events', { //localhost:8080

          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newEvent),
          credentials: 'include',
        });
  
        if (response.ok) {
          const createdEvent = await response.json();
          setEvents([...events, createdEvent]);
          showNotification(`Availability "${title}" was created.`);

          // Auto-refresh events after creating one
          const fetchUpdatedEvents = async () => {
            try {
              const response = await fetch('https://rinksync-backend.onrender.com/events', { //localhost:8080

                credentials: 'include',
              });
              const updatedEvents = await response.json();
              setEvents(updatedEvents); // Update state with new events
            } catch (error) {
              console.error('Error fetching updated events:', error);
              showNotification('Failed to refresh events.');
            }
          };

        await fetchUpdatedEvents(); // Trigger auto-refresh

        } else {
          const error = await response.text();
          console.error('Error creating event:', error);
          showNotification('Error creating event.');
        }
      } catch (err) {
        console.error('Error:', err);
        showNotification('Error creating event.');
      }
    }
  };
  
  

  return (
    <div className="dashboard-container">
            {/* Team Affiliation Modal */}
            {showTeamModal && (
              <div className="team-modal-overlay">
                <div className="team-modal-content">
                  <h3>Complete Your Profile</h3>
                  <p>Please fill out your team affiliation information to proceed.</p>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSaveTeamInfo();
                    }}
                  >
                    <div className="form-group">
                      <label className="white-label">
                        Team Name:
                        <input
                          type="text"
                          value={teamInfo.team}
                          onChange={(e) => setTeamInfo({ ...teamInfo, team: e.target.value })}
                          required
                        />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="white-label">
                        State:
                        <select
                          value={teamInfo.state}
                          onChange={(e) => setTeamInfo({ ...teamInfo, state: e.target.value })}
                          required
                        >
                          <option value="">Select a state</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="white-label">
                        Team Email:
                        <input
                          type="email"
                          value={teamInfo.email}
                          onChange={(e) => setTeamInfo({ ...teamInfo, email: e.target.value })}
                          required
                        />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="white-label">
                        League Affiliation:
                        <select
                          value={teamInfo.league}
                          onChange={(e) => setTeamInfo({ ...teamInfo, league: e.target.value })}
                          required
                        >
                          <option value="">Select a league</option>
                          <option value="IND">Independent</option>
                          <option value="TCHC">TCHC</option>
                          <option value="NP">Not Provided</option>
                          {/* Add more leagues as needed */}
                        </select>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="white-label">
                        Division:
                        <select
                          value={teamInfo.division}
                          onChange={(e) => setTeamInfo({ ...teamInfo, division: e.target.value })}
                          required
                        >
                          <option value="">Select a division</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="NP">Not Provided</option>
                          <option value="NA">Not Applicable</option>
                          {/* Add more leagues as needed */}
                        </select>
                      </label>
                    </div>
                    <div className="team-modal-actions">
                      <button type="submit" className="save-button">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

      <div className="dashboard-main">
        {/* Calendar */}
        <div className="dashboard-calendar">
          <DnDCalendar
            key={events.length}
            localizer={localizer}
            events={events.map((event) => ({
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
            }))}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '80vh', margin: '20px' }}
            selectable
            resizable
            onEventDrop={handleEventDrop}
            onEventResize={handleEventResize}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            draggableAccessor={() => true}

            date={currentDate}
            view={currentView}
            onNavigate={(newDate) => setCurrentDate(newDate)}
            onView={(newView) => setCurrentView(newView)}

            eventPropGetter={(event) => {
              const isSelected = selectedEvent && selectedEvent.id === event.id;
              const baseColor = eventColors[event.type] || '#ccc'; // Default gray if type not found
              const darkerColor = baseColor;
              const backgroundColor = isSelected ? darkerColor : baseColor;
              const boxShadow = isSelected ? '0 4px 8px rgba(0, 0, 0, 0.3)' : 'none';
      
              return {
                  style: {
                      backgroundColor,
                      color: '#fff',
                      borderRadius: '5px',
                      border: 'none',
                      boxShadow,
                      transform: isSelected ? 'scale(1.03)' : 'none',
                      transition: 'all 0.2s ease-in-out',
                  },
              };
          }}
          />
        </div>

        {/* Sidebar */}
        <div className="dashboard-sidebar">
          <div className="dashboard-sidebar-header">
            <span>{user?.name}'s Dashboard</span>
            <img
              src={user?.picture || 'default-profile.jpg'}
              alt="Profile"
              className="dashboard-profile-pic"
            />
          </div>
          <p>Add, edit, or delete availability below:</p>
          <button className="sidebar-button" onClick={handleAddAvailability}>
            Add Availability
          </button>
          <button className="sidebar-button" onClick={handleEditAvailability}>
            Edit Availability
          </button>
          <button className="sidebar-button" onClick={handleDeleteAvailability}>
            Delete Availability
          </button>
            <div className='sidebar-buttons'>
              <button
                  className="cp-button"
                  onClick={() => {
                      if (!selectedEvent) {
                          alert('Please select an event to copy.');
                          return;
                      }
                      setCopiedEvent(selectedEvent);
                      setPasteMode(true);
                      showNotification(`Select a date to paste "${selectedEvent.title}" on the calendar.`);
                  }}
              >
                  Copy Event
              </button>
          
              <button
                className="cp-button"
                onClick={() => {
                  if (!copiedEvent) {
                    alert('No event copied to paste.');
                    return;
                  }
                  const now = moment();
                  const newStart = moment.max(now, moment(copiedEvent.start)); // Ensure new event starts now or later
                  const duration = moment(copiedEvent.end).diff(moment(copiedEvent.start), 'minutes'); // Calculate event duration
                  const newEnd = newStart.clone().add(duration, 'minutes');
                  const newEvent = {
                    ...copiedEvent,
                    id: Date.now(), // New unique ID
                    start: newStart.toISOString(),
                    end: newEnd.toISOString(),
                  };
                  setEvents([...events, newEvent]);
                  showNotification(`Availability pasted.`);
                }}
              >
                Paste Event
              </button>
            </div>
        </div>
      </div>

      {/* Modal for Add/Edit Availability */}
      {availabilityModal.visible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>
              {availabilityModal.action === 'add'
                ? 'Add Availability'
                : 'Edit Availability'}
            </h3>
            <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target;
                  const isAllDay = allDay;
                  const today = moment().startOf("day"); // Current day at midnight

                  if (isAllDay) {
                    console.log("DEBUG: Handling all-day event");
                    const startDate = form.startDate?.value ? moment(form.startDate.value) : null;
                    const endDate = form.endDate?.value ? moment(form.endDate.value) : startDate; // Default to startDate if endDate is missing

                    if (!startDate) {
                      console.error("ERROR: Start date is missing!");
                      alert("Start date is required for all-day events.");
                      return;
                    }

                    if (startDate.isBefore(today)) {
                      console.error("ERROR: All-day event cannot start in the past.");
                      alert("All-day events cannot start in the past.");
                      return;
                    }

                    if (endDate && endDate.isBefore(startDate)) {
                      console.error("ERROR: End date is before start date!");
                      alert("End date cannot be before the start date.");
                      return;
                    }

                    const newEvent = {
                      id: availabilityModal.event?.id || Date.now(),
                      title: form.title?.value || "",
                      allDay: isAllDay,
                      startDate: startDate.format("YYYY-MM-DD"),
                      endDate: endDate.format("YYYY-MM-DD"),
                      type: form.type?.value || "Home",
                    };

                    console.log("DEBUG: Final all-day event payload:", newEvent);
                    handleSaveAvailability(newEvent);
                  } else {
                    console.log("DEBUG: Handling time-based event");

                    // Ensure all required fields are present
                    const startDate = form.startDate?.value;
                    const startHour = form.startHour?.value;
                    const startMinute = form.startMinute?.value;
                    const startAmPm = form.startAmPm?.value;
                    const endDate = form.endDate?.value;
                    const endHour = form.endHour?.value;
                    const endMinute = form.endMinute?.value;
                    const endAmPm = form.endAmPm?.value;

                    console.log("DEBUG: Raw Input Values:");
                    console.log("Start Date:", startDate);
                    console.log("Start Hour:", startHour);
                    console.log("Start Minute:", startMinute);
                    console.log("Start AM/PM:", startAmPm);
                    console.log("End Date:", endDate);
                    console.log("End Hour:", endHour);
                    console.log("End Minute:", endMinute);
                    console.log("End AM/PM:", endAmPm);

                    if (!startDate || !startHour || !startMinute || !startAmPm || !endDate || !endHour || !endMinute || !endAmPm) {
                      console.error("ERROR: Start and/or end date/time is missing!");
                      alert("Start and end date/time are required.");
                      return;
                    }

                    // Convert to 24-hour format before passing to moment.js
                    const startHour24 = startAmPm === "PM" ? (parseInt(startHour) % 12) + 12 : parseInt(startHour);
                    const endHour24 = endAmPm === "PM" ? (parseInt(endHour) % 12) + 12 : parseInt(endHour);

                    const startTimeStr = `${startHour24}:${startMinute}`;
                    const endTimeStr = `${endHour24}:${endMinute}`;

                    console.log("DEBUG: Constructed startTimeStr:", startTimeStr);
                    console.log("DEBUG: Constructed endTimeStr:", endTimeStr);

                    const startMoment = moment(`${startDate} ${startTimeStr}`, "YYYY-MM-DD HH:mm");
                    const endMoment = moment(`${endDate} ${endTimeStr}`, "YYYY-MM-DD HH:mm");

                    console.log("DEBUG: Parsed startMoment:", startMoment.isValid() ? startMoment.toISOString() : "Invalid");
                    console.log("DEBUG: Parsed endMoment:", endMoment.isValid() ? endMoment.toISOString() : "Invalid");

                    if (!startMoment.isValid() || !endMoment.isValid() || startMoment.isAfter(endMoment)) {
                      console.error("ERROR: Invalid start or end time.");
                      alert("Invalid start or end time.");
                      return;
                    }

                    const newEvent = {
                      id: availabilityModal.event?.id || Date.now(),
                      title: form.title?.value || "",
                      allDay: isAllDay,
                      startDate: startDate,
                      startAmPm: startAmPm,
                      endDate: endDate,
                      endAmPm: endAmPm,
                      startHour: startHour24,
                      startMinute: startMinute,
                      endHour: endHour24,
                      endMinute: endMinute,
                      start: startMoment.toISOString(),
                      end: endMoment.toISOString(),
                      type: form.type?.value || "Home",
                    };

                    console.log("DEBUG: Final time-based event payload:", newEvent);
                    handleSaveAvailability(newEvent);
                  }
                }}

            >
              <label>
                  Title:
                  <input
                      type="text"
                      name="title"
                      value={newEvent.title || ''}
                      onChange={(e) =>
                          setNewEvent((prev) => ({ ...prev, title: e.target.value }))
                      }
                      required
                  />
              </label>
              <label>
                All Day:
                <input
                  type="checkbox"
                  name="allDay"
                  checked={allDay}
                  onChange={(e) => setAllDay(e.target.checked)}
                />
              </label>

              {allDay ? (
                <>
                  <label>
                    Start Date:
                      <input
                        type="date"
                        name="startDate"
                        value={newEvent.startDate || ""}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, startDate: e.target.value }))}
                        required
                      />
                  </label>
                </>
              ) : (
                <>
                  {/* Start Section */}
                  <div className="modal-section">
                    <label>
                      Start Date:
                      <input
                        type="date"
                        name="startDate"
                        value={newEvent.startDate || ""}
                        onChange={(e) =>
                          setNewEvent((prev) => ({ ...prev, startDate: e.target.value }))
                        }
                        required
                      />
                    </label>
                  {/* Start Time */}
                  <label>
                    Start Time:
                    <div className="time-picker-inline">
                      {/* Hour Selector */}
                      <select
                        name="startHour"
                        value={newEvent.startHour || "12"}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, startHour: e.target.value }))}
                        required
                      >
                        {[...Array(12).keys()].map((hour) => (
                          <option key={hour + 1} value={hour + 1}>
                            {hour + 1}
                          </option>
                        ))}
                      </select>
                      :
                      {/* Minute Selector */}
                      <select
                        name="startMinute"
                        value={newEvent.startMinute || "00"}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, startMinute: e.target.value }))}
                        required
                      >
                        {[...Array(60).keys()].map((minute) => (
                          <option key={minute} value={minute.toString().padStart(2, "0")}>
                            {minute.toString().padStart(2, "0")}
                          </option>
                        ))}
                      </select>
                      {/* AM/PM Selector */}
                      <select
                        name="startAmPm"
                        value={newEvent.startAmPm || "AM"}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, startAmPm: e.target.value }))}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </label>
                  </div>

                  {/* End Section */}
                  <div className="modal-section">
                    <label>
                      End Date:
                      <input
                        type="date"
                        name="endDate"
                        value={newEvent.endDate || ""}
                        onChange={(e) =>
                          setNewEvent((prev) => ({ ...prev, endDate: e.target.value }))
                        }
                        required
                      />
                    </label>

                  {/* End Time */}
                  <label>
                    End Time:
                    <div className="time-picker-inline">
                      {/* Hour Selector */}
                      <select
                        name="endHour"
                        value={newEvent.endHour || "12"}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, endHour: e.target.value }))}
                        required
                      >
                        {[...Array(12).keys()].map((hour) => (
                          <option key={hour + 1} value={hour + 1}>
                            {hour + 1}
                          </option>
                        ))}
                      </select>
                      :
                      {/* Minute Selector */}
                      <select
                        name="endMinute"
                        value={newEvent.endMinute || "00"}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, endMinute: e.target.value }))}
                        required
                      >
                        {[...Array(60).keys()].map((minute) => (
                          <option key={minute} value={minute.toString().padStart(2, "0")}>
                            {minute.toString().padStart(2, "0")}
                          </option>
                        ))}
                      </select>
                      {/* AM/PM Selector */}
                      <select
                        name="endAmPm"
                        value={newEvent.endAmPm || "AM"}
                        onChange={(e) => setNewEvent((prev) => ({ ...prev, endAmPm: e.target.value }))}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </label>

                  </div>

                </>
              )}

              <label>
                Type:
                <select
                  name="type"
                  defaultValue={availabilityModal.event?.type || 'Home'}
                  required
                >
                  <option value="Home">Home</option>
                  <option value="Away">Away</option>
                  <option value="Home & Away">Home & Away</option>
                </select>
              </label>

              {/* Recurrence Options */}
              <label>
                  Recurring Event:
                  <input
                      type="checkbox"
                      name="recurringEvent"
                      checked={!!recurringEvent.frequency}
                      onChange={(e) =>
                          setRecurringEvent((prev) => ({
                              ...prev,
                              frequency: e.target.checked ? 'daily' : '',
                              endDate: e.target.checked ? prev.endDate : null,
                          }))
                      }
                  />
              </label>
              {recurringEvent.frequency && (
                    <>
                        <label>
                            Recurrence:
                            <select
                                name="recurrence"
                                value={recurringEvent.frequency}
                                onChange={(e) =>
                                    setRecurringEvent((prev) => ({ ...prev, frequency: e.target.value }))
                                }
                                required
                            >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </select>
                        </label>
                        <label>
                            Recurrence End Date:
                            <input
                                type="date"
                                name="recurrenceEndDate"
                                value={recurringEvent.endDate || ""}
                                onChange={(e) =>
                                    setRecurringEvent((prev) => ({ ...prev, endDate: e.target.value }))
                                }
                                required
                            />
                        </label>
                    </>
                )}

              <div className="modal-actions">
                <button type="submit" className="save-button">
                  Save
                </button>
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => {
                      setAvailabilityModal({ visible: false, action: null, event: null });
                      setRecurringEvent({ frequency: '', endDate: null }); // Reset recurring event state
                    }}
                  >
                    Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}


      {notification && (
        <div className="notification-popup">
          <p>{notification}</p>
        </div>
      )}

        {pasteMode && (
            <button
                className="cancel-paste-button"
                onClick={() => {
                    setPasteMode(false);
                    setCopiedEvent(null);
                    showNotification('Paste mode canceled.');
                }}
            >
                Cancel Paste
            </button>
        )}
    </div>
  );
};

export default Dashboard;