import React, { useState, useEffect } from 'react';
import './ProtectedRoute.css';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, children }) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isAuthenticated === false) {
      setShowPopup(true); // Show the popup if not authenticated
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return children; // Render the protected content if authenticated
  }

  return (
    <>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Access Denied</h3>
            <p>You must log in to access this page.</p>
            <button onClick={() => navigate('/')} className="popup-close-button">
              Return to Home
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProtectedRoute;