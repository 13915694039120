// Header.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import RinkSyncLogo from './images/rinksyncLogo.jpeg';
import { use } from 'react';

const Header = ({ isOpen, toggleNav, isAuthenticated, userProfile, handleLogin, handleLogout }) => {
  const location = useLocation();
  const isSchedulerPage = location.pathname === '/scheduler';

  return (
    <header className="header-container">
      <div className="hamburger-container" onClick={toggleNav}>
        <div className={`hamburger ${isOpen ? 'open' : ''}`}>
          <div className="line line1"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
        </div>
      </div>
        <Link to="/">
          <img src={RinkSyncLogo} alt="RinkSync Logo" className="rinksync-logo" />
        </Link>
        {/*<Link to="/" className="logo">RinkSync</Link>*/}
        {isSchedulerPage && (
        <button 
          className="send-invite-button header-invite-button"
          onClick={() => alert('Send Custom Invite clicked!')}
        >
          Send Custom Invite
        </button>
      )}
      <div className="login-section">
        {isAuthenticated ? (
          <div className="user-info">
            {userProfile?.picture && (
              <img
                src={userProfile.picture}
                alt="Profile"
                className="profile-pic"
              />
            )}
            <span className="user-name">{userProfile?.name}</span>
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </div>
        ) : (
          <button onClick={handleLogin} className="login-button">
            Login
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;