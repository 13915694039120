// Sidebar.js
import React from 'react';
import './Sidebar.css';
import { Routes, Route } from 'react-router-dom';
import Landing from './Landing';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleNav }) => {
  return (
    <>
      {/* Background Overlay */}
      <div
        className={`background-overlay ${isOpen ? 'visible' : ''}`}
        onClick={isOpen ? toggleNav : null}
      />
      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'visible' : ''}`}>
        <div className="button-container flex flex-col gap-4 mt-4">
          {/* Add Sidebar Navigation Buttons */}
          <NavLink
            to="/"
            className="sidebar-link"
            onClick={toggleNav} // Close sidebar when clicking a link
          >
            Home
          </NavLink>
          <NavLink
            to="/dashboard"
            className="sidebar-link"
            onClick={toggleNav}
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/scheduler"
            className="sidebar-link"
            onClick={toggleNav}
          >
            Scheduler
          </NavLink>
          <NavLink
            to="/settings"
            className="sidebar-link"
            onClick={toggleNav}
          >
            Settings
          </NavLink>
          {/* Add other links as needed */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
