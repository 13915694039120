import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Scheduler.css';
import { useAuth } from './authContext';


const localizer = momentLocalizer(moment);

const Scheduler = () => {
  const [events, setEvents] = useState([]);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [userLeague, setUserLeague] = useState('all'); // State for user's default league
  const [showModal, setShowModal] = useState(false);
  const [senderEmail, setSenderEmail] = useState('');
  const [senderPassword, setSenderPassword] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [teamNames, setTeamNames] = useState([])



  const { user } = useAuth();

  const [filters, setFilters] = useState({
    type: 'all',
    state: 'all',
    league: 'all',
    division: 'all',
    teamName: 'all',
    commonAvailability: false,
  });
  const pageSize = 3;

  const handleSendEmailClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  useEffect(() => {
      const fetchTeamNames = async () => {
          try {
              const response = await fetch('https://rinksync-backend.onrender.com/all-teams', { //localhost:8080
                  credentials: 'include',
              });
              const data = await response.json();
              setTeamNames(data);
          } catch (error) {
              console.error('Error fetching team names:', error);
          }
      };

      fetchTeamNames();
  }, []);


  useEffect(() => {
    // Fetch user's affiliated league when the component mounts
    const fetchUserLeague = async () => {
        if (user && user.sub) {
            try {
                const response = await fetch(`https://rinksync-backend.onrender.com/user/${user.sub}/team-info`, { //localhost:8080
                    credentials: 'include',
                });
                const data = await response.json();
                if (data && data.league) {
                    setFilters((prevFilters) => ({
                        ...prevFilters,
                        league: data.league || 'all', // Set the default league filter
                    }));
                }
            } catch (error) {
                console.error('Error fetching user league:', error);
            }
        }
    };

    fetchUserLeague();
}, [user]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const queryParams = new URLSearchParams();
        if (filters.type !== 'all') queryParams.append('type', filters.type);
        if (filters.state !== 'all') queryParams.append('state', filters.state);
        if (filters.league !== 'all') queryParams.append('league', filters.league);
        if (filters.division !== 'all') queryParams.append('division', filters.division);
        if (filters.commonAvailability) queryParams.append('commonAvailability', 'true');
        if (filters.teamName !== 'all') queryParams.append('teamName', filters.teamName);

        console.log('Request Query Params:', queryParams.toString()); // Add this line

        const response = await fetch(
          `https://rinksync-backend.onrender.com/filtered-events?${queryParams.toString()}`, //localhost:8080
          { credentials: 'include' }
        );
  
        const data = await response.json();
        const formattedEvents = data.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

      fetchEvents();

  }, [filters]);


  useEffect(() => {
    if (selectedDate) {
      const filteredEvents = events.filter((event) => {
          const isSameDate = new Date(event.start).toDateString() === selectedDate.toDateString()
          const matchesTeamFilter = filters.teamName === 'all' || event.team_name === filters.teamName;
          return isSameDate && matchesTeamFilter;
    });
      setSelectedDateEvents(filteredEvents);
      setCurrentPage(0);
    }
  }, [selectedDate, events]);

  const handleDateSelect = (slotInfo) => {
    setSelectedDate(new Date(slotInfo.start));
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * pageSize < selectedDateEvents.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

const handleFilterChange = (e) => {
  const { name, value, type, checked } = e.target;
  setFilters((prevFilters) => ({
    ...prevFilters,
    [name]: type === 'checkbox' ? checked : value,
  }));
  console.log("Updated Filters: ", {
    ...filters,
    [name]: type === 'checkbox' ? checked : value,
  });
};


  const handleSendEmail = async () => {
    if (!selectedEvent || !senderEmail || !senderPassword) {
      alert('Please fill out all fields.');
      return;
    }

    try {
      const response = await fetch('https://rinksync-backend.onrender.com/send-invitation-email', { //localhost:8080
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          senderEmail,
          senderPassword, // Pass the password to the backend
          receiverEmail: selectedEvent.receiver_email,
          event: selectedEvent,
        }),
      });

      if (response.ok) {
        alert('Invitation email sent successfully!');
      } else {
        alert('Failed to send the email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setShowModal(false);
      setSenderEmail('');
      setSenderPassword('');
    }
  };

  useEffect(() => {
    console.log("Current Filters:", filters);
}, [filters]);

  return (
    <div className="scheduler-container">
      <div className="scheduler-main">
        {/* Left Section (Filters + Calendar) */}
        <div className="scheduler-left">
          {/* Calendar Section */}
          <div className="scheduler-calendar">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '80vh', margin: '20px' }}
              selectable
              onSelectSlot={handleDateSelect}
              onSelectEvent={handleDateSelect}
              eventPropGetter={(event) => {
                const eventTypeClass = event.type === 'Home'
                    ? 'rbc-event-home'
                    : event.type === 'Away'
                    ? 'rbc-event-away'
                    : 'rbc-event-home-away';
        
                return {
                    className: eventTypeClass,
                };
            }}
              dayPropGetter={(date) => {
                if (selectedDate && date.toDateString() === selectedDate.toDateString()) {
                  return {
                    style: {
                      backgroundColor: '#ffd700', // Highlight color
                      border: '2px solid #3666b3',
                    },
                  };
                }
                return {};
              }}
            />
          </div>
        </div>

      {/* Sidebar */}
      <div className="scheduler-sidebar">
        {/* Filters Section */}
        <div className="scheduler-filters">
            <label>
              Common Availability:
              <input
                  type="checkbox"
                  name="commonAvailability"
                  checked={filters.commonAvailability || false}
                  onChange={(e) =>
                      setFilters((prevFilters) => ({
                          ...prevFilters,
                          commonAvailability: e.target.checked,
                      }))
                  }
                />
            </label>
            <label>
                Team Name:
                <select
                    name="teamName"
                    value={filters.teamName}
                    onChange={handleFilterChange}
                >
                    <option value="all">All</option>
                    {teamNames.map((teamName) => (
                        <option key={teamName} value={teamName}>
                            {teamName}
                        </option>
                    ))}
                </select>
            </label>
            <label>
              Type:
              <select
                name="type"
                value={filters.type}
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value="Home">Home</option>
                <option value="Away">Away</option>
                <option value="Home & Away">Home & Away</option>
              </select>
            </label>
            <label>
              State:
              <select
                name="state"
                value={filters.state}
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </label>
            <label>
              League:
              <select
                name="league"
                value={filters.league}
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value="TCHC">TCHC</option>
                <option value="IND">Independent</option>
                <option value="NP">Not Provided</option>
              </select>
            </label>
            <label>
              Division:
              <select
                name="division"
                value={filters.division}
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value="One">1</option>
                <option value="Two">2</option>
                <option value="Three">3</option>
              </select>
            </label>
          </div>
          <h3>Events on {selectedDate ? selectedDate.toDateString() : '...'}</h3>
            {selectedDateEvents.length > 0 ? (
                  <>
                      <ul className="event-list">
                          {selectedDateEvents
                              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                              .map((event) => {
                                  const eventCardClass = event.type === 'Home'
                                      ? 'event-home'
                                      : event.type === 'Away'
                                      ? 'event-away'
                                      : 'event-home-away';

                                  return (
                                      <div key={event.id} className={`event-card ${eventCardClass}`}>
                                          <div className="event-info">
                                              <h4 className="event-title">{event.title}</h4>
                                              <p className="event-team"><strong>Team:</strong> {event.team_name || 'N/A'}</p>
                                              <p className="event-time">
                                                  {event.all_day
                                                      ? 'All Day Availability'
                                                      : `${new Date(event.start).toLocaleTimeString()} - ${new Date(event.end).toLocaleTimeString()}`}
                                              </p>
                                              <p className="event-type"><strong>Type:</strong> {event.type}</p>
                                              <p className="event-league"><strong>League:</strong> {event.league || 'N/A'}</p>
                                              <p className="event-division"><strong>Division:</strong> {event.division || 'N/A'}</p>
                                              <p className="event-state"><strong>State:</strong> {event.state || 'N/A'}</p>
                                          </div>
                                          <button
                                              className="send-invite-button"
                                              onClick={() => handleSendEmailClick(event)}
                                          >
                                              Send Game Invite
                                          </button>
                                      </div>
                                  );
                              })}
                      </ul>
                      {showModal && (
                        <div className="modal">
                          <div className="modal-content">
                            <h2>Send Game Invite</h2>
                            <input
                              type="email"
                              placeholder="Your Email"
                              value={senderEmail}
                              onChange={(e) => setSenderEmail(e.target.value)}
                            />
                            <input
                              type="password"
                              placeholder="Your Email Password"
                              value={senderPassword}
                              onChange={(e) => setSenderPassword(e.target.value)}
                            />
                            <button className = "send-button" onClick={handleSendEmail}>Send</button>
                            <button className = "cancel-button" onClick={() => setShowModal(false)}>Cancel</button>
                          </div>
                        </div>
                      )}
                  <div className="pagination-controls">
                    {(currentPage + 1) * pageSize < selectedDateEvents.length && (
                        <button
                            className="pagination-button"
                            onClick={handleNextPage}
                        >
                            Next &darr;
                        </button>
                    )}
                    
                    <span className="page-info">
                        Page {currentPage + 1} of {Math.ceil(selectedDateEvents.length / pageSize)}
                    </span>

                    {currentPage > 0 && (
                        <button
                            className="pagination-button"
                            onClick={handlePreviousPage}
                        >
                            &uarr; Previous
                        </button>
                    )}
                </div>

            </>
          ) : (
            <p>No events for this date.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Scheduler;