import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import ProtectedRoute from './ProtectedRoute';
import Landing from './Landing';
import Header from './Header';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Scheduler from './Scheduler';
import './App.css';
import { AuthProvider } from './authContext';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [passwordModal, setPasswordModal] = useState({ visible: false, view: '' });
  const [password, setPassword] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [shouldCheckAuth, setShouldCheckAuth] = useState(true);



    // Check authentication status on app load
    const checkAuthStatus = async () => {
      console.log('Checking authentication status...');
      try {
          const response = await axios.get('https://rinksync-backend.onrender.com/auth-status', { //localhost:8080
              withCredentials: true,
          });
  
          if (response.data.isAuthenticated) {
              console.log('User is authenticated');
              setIsAuthenticated(true);
              setUserProfile(response.data.userProfile);
          } else {
              console.log('User is not authenticated');
              setIsAuthenticated(false);
              setUserProfile(null);
          }
      } catch (error) {
          console.log('Auth status check failed, assuming not authenticated.');
          setIsAuthenticated(false);
          setUserProfile(null);
      }
  };
  
  
  
// Check authentication status on app load
useEffect(() => {
  if (shouldCheckAuth) {
      console.log('Checking authentication status after logout...');
      checkAuthStatus();
      setShouldCheckAuth(false); // Reset flag to avoid repeated checks
  }
}, [shouldCheckAuth]);

  const handleLogin = () => {
    window.location.href = 'https://rinksync-backend.onrender.com/login'; //localhost:8080
  };

  const clearCookies = () => {
    console.log("Document Cookie String:", document.cookie);

    const cookies = document.cookie.split(";");
    if (cookies.length === 0 || (cookies.length === 1 && cookies[0] === "")) {
        console.log("No cookies found to clear.");
    } else {
        cookies.forEach((cookie) => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
            console.log(`Found cookie: ${name}`);
        });
    }
    

    cookies.forEach((cookie) => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
        
        if (name) {
            console.log(`Clearing cookie: ${name}`);
            const domain = window.location.hostname.includes("localhost")
            ? "localhost"
            : "hockeynew.onrender.com";
          
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain}`;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${domain}`;
          
        } else {
            console.log("Found an empty cookie or failed to parse cookie name.");
        }
    });

    localStorage.clear();
    sessionStorage.clear();
};


const handleLogout = async () => {
  console.log('Logout button clicked');

  // Clear front-end authentication state immediately
  setIsAuthenticated(false);
  setUserProfile(null);
  setShouldCheckAuth(false); // Prevent premature auth checks

  // Log cookies before clearing
  console.log("Before clearing cookies, document.cookie:", document.cookie);

  // Clear all cookies and local/session storage
  clearCookies();

  // Log cookies after clearing
  console.log("After clearing cookies, document.cookie:", document.cookie);

  try {
      console.log('Attempting to hit backend logout endpoint...');
      await fetch('https://rinksync-backend.onrender.com/logout', { //localhost:8080
          credentials: 'include',
          mode: 'no-cors'
      });

      console.log('Backend logout endpoint hit successfully');

      // Now enable auth status check
      setShouldCheckAuth(true);

  } catch (error) {
      console.error('Failed to hit backend logout endpoint:', error);
  }
};


  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
  <AuthProvider>
    <Router>
      <div className={`app-container ${isOpen ? 'sidebar-open' : ''}`}>
        <Header
          isOpen={isOpen}
          toggleNav={toggleNav}
          isAuthenticated={isAuthenticated}
          userProfile={userProfile}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
        />
        <Sidebar isOpen={isOpen} toggleNav={toggleNav} />
        <main className="content-container">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/scheduler"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Scheduler />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
        {showMessageModal && (
          <div className="message-modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-md">
              <p className="text-center text-lg mb-4">{message}</p>
              <button
                onClick={() => setShowMessageModal(false)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Understood
              </button>
            </div>
          </div>
        )}
      </div>
    </Router>
    </AuthProvider>
  );
};

export default App;